.intro {
    display: flex;
    justify-content: center;
    /*padding-bottom: 10px;*/
}

.icons {
    display: flex;
    padding: 0;
    list-style-type: none;
    margin: 0;
    justify-content: center;
}

.icons a {
    padding-right: 5px;
    padding-left: 5px;
    /*color: black;*/
}
